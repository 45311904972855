import React from 'react';
import portfolioData from './portfolioData.json';

function Portfolio() {
    return (
        <div>
            {portfolioData.map((item, itemIndex) => (
                <div className="my-10" key={itemIndex}>
                    <h1>{item.title}</h1>
                    {item.levels.map((level, levelIndex) => (
                        <div className="my-7" key={levelIndex}>
                            <p>{level.level}</p>
                            {level.skills.map((skill, skillIndex) => (
                                <div className="my-5" key={skillIndex}>
                                    <p className="my-1">{skill.skill}</p>
                                    <p>{skill.level}</p>
                                    <p>{skill.description}</p>
                                    <p>{skill.projects ? skill.projects.sort(() => Math.random() - 0.5).join(', ') : ''}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default Portfolio;