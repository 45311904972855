import React, {useState, useEffect, useRef} from 'react';
import {BrowserRouter as Router, Route, Link, Routes, useNavigate} from 'react-router-dom';
import './App.css';

import {Analytics} from "@vercel/analytics/react";

import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {faReact} from '@fortawesome/free-brands-svg-icons';
import {motion, useTransform, useElementScroll} from 'framer-motion';

import TailwindLogo from './img/Tailwind_CSS_logo.svg.png';

import Biographie from './biographie';
import Projects from './projects';
import Contact from './contact';
import ProjectDetails from './projectDetails';
import ScrollIndicator from "./scrollIndicator";
import Portfolio from './portfolio';
import BackButton from "./BackButton";

function App() {
    const [currentSubsection, setCurrentSubsection] = useState('');
    const [animate, setAnimate] = useState(false);
    const [selectedLink, setSelectedLink] = useState('');
    const [squarePosition, setSquarePosition] = useState(0);
    const [isMoving, setIsMoving] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const bioButtonRef = useRef(null);
    const ref = useRef(null);
    const {scrollYProgress} = useElementScroll(ref);
    const [navEndValue, setNavEndValue] = useState('-9px');
    const [navHeight, setNavHeight] = useState('109px');

    useEffect(() => {
        const updateNavEndValue = () => {
            if (window.innerWidth >= 1024) { // Assuming 1024px as the breakpoint for desktop
                setNavEndValue('-32px');
                setNavHeight('135px');
            } else {
                setNavEndValue('-9px');
                setNavHeight('109px');
            }
        };

        updateNavEndValue();
        window.addEventListener('resize', updateNavEndValue);

        return () => {
            window.removeEventListener('resize', updateNavEndValue);
        };
    }, []);

    const navPosition = useTransform(scrollYProgress, [0, 0.2], ['0%', navEndValue]);

    const lineWidth = useTransform(scrollYProgress, [0, 0.13], ['calc(100% - 80px)', '100vw']);
    //const lineHeight = useTransform(scrollYProgress, [0.026, 0.118], ['0%', '126px']);
    const lineMargin = useTransform(scrollYProgress, [0, 0.2], ['40px', '0px']);
    //const scrollIndicatorOpacity = useTransform(scrollYProgress, [0, 0.13], [0, 1]);

    //const lineWidth = '100%';
    const lineHeight = '99px';
    //const lineMargin = '0';
    const scrollIndicatorOpacity = 1;

    const leftLogo = useTransform(scrollYProgress, [0, 0.13], ['40px', '450px']);
    const topLogo = useTransform(scrollYProgress, [0, 0.13], ['50px', '35px']);
    const scaleLogo = useTransform(scrollYProgress, [0, 0.13], ['0.75', '0.4']);

    useEffect(() => {
        setAnimate(true);
        const timer = setTimeout(() => {
            setAnimate(false);
        }, 300);

        return () => clearTimeout(timer);
    }, [currentSubsection]);

    const [parentClass, setParentClass] = useState('relative');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isOver, setIsOver] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setParentClass('specific-class');
        }, 1500);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoaded(true);
        }, 2500);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsOver(true);
        }, 4000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        //bioButtonRef.current.click();
    }, []);

    return (
        <Router>

            <div
                className={`fixed top-0 left-0 w-screen h-full bg-bg-custom -z-10 ${isOver ? '-z-10' : 'z-10'} ${isLoaded ? 'opacity-0 transition-opacity duration-1000 delay-5000' : ' z-10 opacity-100'}`}></div>

            <div className="flex flex-col justify-center h-full">

                <div
                    className={`absolute parentClass transform z-20 ${isLoaded ? 'top-5 left-4 sm:top-12 sm:left-10 scale-[40%] sm:scale-75 transition-all duration-5000 ease-in-out' : 'scale-50 sm:scale-150 xl:scale-[1.7] 2xl:scale-[2.2] 3xl:scale-[2.4] top-1/2 left-[15%] -translate-x-1/2 -translate-y-1/2'}`}>
                    <div id="top">
                        <div
                            className={`nsa ${parentClass === 'specific-class' ? 'rounded -rotate-[45deg] top-0 left-0' : 'rotate-0'} top-0 left-0`}></div>
                        <div
                            className={`nsa ${parentClass === 'specific-class' ? 'rounded -rotate-[70deg] left-[186px] top-0' : 'rotate-0 top-0 left-[200px]'} `}></div>
                        <div
                            className={`nsa ${parentClass === 'specific-class' ? 'rounded rotate-[60deg] left-[250px] top-0' : 'rotate-0 top-0 left-[390px]'} `}></div>
                        <div
                            className={`nsaSMALL ${parentClass === 'specific-class' ? 'rounded rotate-[45deg] left-[350px] top-[20px]' : 'rotate-0 top-0 left-[450px]'} `}></div>
                        <div
                            className={`nsaSMALL ${parentClass === 'specific-class' ? 'rounded -rotate-[45deg] left-[350px] -top-[12px]' : 'rotate-0 top-0 left-[515px]'} `}></div>
                        <div
                            className={`h-[30px] w-[50px] absolute bg-text transition-all duration-700 ease-in-out ${parentClass === 'specific-class' ? 'rounded left-[239px] -top-[37px]' : 'top-0 left-[450px]'}`}></div>
                    </div>
                    <div className="filler fillerTop"></div>
                    <div id="absolute middle flex flex-col">
                        <div
                            className={`nsa ${parentClass === 'specific-class' ? 'rounded rotate-[45deg]' : 'top-[50px]'} top-[50px]`}></div>
                        <div
                            className={`nsaBIG ${parentClass === 'specific-class' ? 'rounded w-[324px]' : 'left-[100px] top-[50px] w-[490px]'} left-[100px] top-[50px]`}></div>


                        <div
                            className={`nsa ${parentClass === 'specific-class' ? 'rounded rotate-90 left-[100px] top-[50px]' : 'top-0 left-[100px]'}`}></div>
                        <div
                            className={`nsa ${parentClass === 'specific-class' ? 'rounded rotate-90 left-[100px] top-[50px]' : '-top-[25px] left-[100px]'} top-[100px]`}></div>


                        <div
                            className={`nsa ${parentClass === 'specific-class' ? 'rounded rotate-90 left-[215px]' : 'left-[292px] -top-[25px]'} top-0`}></div>
                        <div
                            className={`nsa ${parentClass === 'specific-class' ? 'rounded rotate-90 left-[215px]' : 'left-[292px] -top-[25px]'} top-[100px]`}></div>
                    </div>

                    <div id="bot">
                        <div
                            className={`nsa ${parentClass === 'specific-class' ? 'rounded -rotate-[45deg]' : 'rotate-0 top-[100px]'} top-[100px]`}></div>
                        <div
                            className={`nsa ${parentClass === 'specific-class' ? 'rounded -rotate-[70deg] left-[150px]' : 'rotate-0 left-[200px] top-[100px]'} top-[100px]`}></div>
                        <div
                            className={`nsa ${parentClass === 'specific-class' ? 'rounded rotate-[60deg] left-[250px]' : 'rotate-0 left-[390px]'} top-[100px]`}></div>

                        <div
                            className={`nsa ${parentClass === 'specific-class' ? 'rounded rotate-90 left-[308px]' : 'rotate-0 left-[450px] -top-[25px]'} top-[100px]`}></div>
                        <div
                            className={`nsaSMALL ${parentClass === 'specific-class' ? 'rounded rotate-[45deg] left-[350px] top-[115px]' : 'rotate-0 top-[100px] left-[450px]'} `}></div>
                        <div
                            className={`nsaSMALL ${parentClass === 'specific-class' ? 'rounded -rotate-[45deg] left-[350px] top-[83px]' : 'rotate-0 top-[100px] left-[515px]'} `}></div>
                        <div
                            className={`w-[20px] absolute bg-text transition-all duration-700 ease-in-out ${parentClass === 'specific-class' ? 'rounded h-[35px] left-[404.6px] top-[49px]' : 'top-[100px] h-[30px] left-[450px]'}`}></div>
                    </div>
                    <div className="filler fillerBot"></div>
                </div>


                <motion.div
                    style={{left: leftLogo, top: topLogo, scale: scaleLogo}}
                    className="hidden fixed parentClass transform z-20  transition-all duration-5000 ease-in-out">
                    <div>
                        <div
                            className="nsa rounded -rotate-[45deg] top-0 left-0"></div>
                        <div
                            className="nsa rounded -rotate-[70deg] left-[186px] top-0"></div>
                        <div
                            className="nsa rounded rotate-[60deg] left-[250px] top-0"></div>
                        <div
                            className="nsaSMALL rounded rotate-[45deg] left-[350px] top-[20px]"></div>
                        <div
                            className="nsaSMALL rounded -rotate-[45deg] left-[350px] -top-[12px]"></div>
                        <div
                            className="h-[30px] w-[50px] absolute bg-text transition-all duration-700 ease-in-out rounded left-[239px] -top-[37px]"></div>
                    </div>
                    <div className="absolute middle flex flex-col">
                        <div
                            className={`nsa rounded rotate-[45deg] top-[50px]`}></div>
                        <div
                            className={`nsaBIG rounded w-[324px] left-[100px] top-[50px]`}></div>


                        <div
                            className={`nsa rounded rotate-90 left-[100px] top-[50px]`}></div>
                        <div
                            className={`nsa rounded rotate-90 left-[100px] top-[50px] top-[100px]`}></div>


                        <div
                            className={`nsa rounded rotate-90 left-[215px] top-0`}></div>
                        <div
                            className={`nsa rounded rotate-90 left-[215px] top-[100px]`}></div>
                    </div>

                    <div>
                        <div
                            className={`nsa rounded -rotate-[45deg] top-[100px]`}></div>
                        <div
                            className={`nsa rounded -rotate-[70deg] left-[150px] top-[100px]`}></div>
                        <div
                            className={`nsa rounded rotate-[60deg] left-[250px] top-[100px]`}></div>

                        <div
                            className={`nsa rounded rotate-90 left-[308px] top-[100px]`}></div>
                        <div
                            className={`nsaSMALL rounded rotate-[45deg] left-[350px] top-[115px] `}></div>
                        <div
                            className={`nsaSMALL rounded -rotate-[45deg] left-[350px] top-[83px] `}></div>
                        <div
                            className={`w-[20px] absolute bg-text transition-all duration-700 ease-in-out rounded h-[35px] left-[404.6px] top-[49px]`}></div>
                    </div>
                </motion.div>

                <div className="flex flex-row justify-center">
                    <div className="flex flex-col justify-center w-full">
                        <div
                            className="invisible flex items-baseline text-4xl sm:text-7xl h-16 sm:h-36 text-text font-extrabold tracking-wider mx-4 sm:mx-10 mt-5 font-josefin whitespace-nowrap">Soren
                            Starck
                            <div className="w-4 h-4 ml-2 bg-text rounded-sm"></div>
                        </div>

                        <div className="flex justify-center hidden">
                            <div className="w-full h-[1px] bg-text my-5"></div>
                            <div
                                className="text-sm sm:text-xl text-text font-bold tracking-wider mx-2 mt-2 font-josefin whitespace-nowrap">React
                                Tailwind
                            </div>
                            <div className="w-1/4 h-[1px] bg-text my-5"></div>
                        </div>

                        <div
                            className="hidden md:flex absolute top-7 right-7 flex-col sm:flex-row items-center space-x-2 text-lg font-bold">
                            <p>Réalisé avec</p>
                            <FontAwesomeIcon icon={faReact} size="1x"
                                             className="text-blue-400 hover:animate-spin transition-all ease-in-out"/>
                            <p>React et</p>
                            <img src={TailwindLogo} alt="Tailwind CSS Logo" className="h-4 hover:transform transition hover:skew-y-6 hover:skew-x-6 hover:scale-90"/>
                        </div>

                        <div className="flex justify-center hidden">
                            <div className="w-1/4 h-[1px] bg-text my-5"></div>
                            <div
                                className="text:sm sm:text-xl text-text font-bold tracking-wider mx-2 mt-2 font-josefin whitespace-nowrap">Étudiant
                                en informatique et
                                développeur full-stack
                            </div>
                            <div className="w-full h-[1px] bg-text my-5"></div>
                        </div>
                    </div>
                    <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="Profile"
                         className="invisible fixed top-5 right-10 rounded-full h-24 w-24 sm:h-36 sm:w-36"/>
                </div>

                <motion.div
                    className={`sticky flex flex-row w-screen h-[109px] sm:h-[130px] justify-between backdrop-blur-xl z-[2]`}
                    style={{top: navPosition}}>
                    <div className="flex flex-row w-screen absolute top-[10px] sm:top-8">
                        <motion.div
                            className="hidden sm:block"
                            style={{width: lineMargin}}
                        ></motion.div>
                        <motion.div
                            className="w-full border-b-[2px] border-text"></motion.div>
                        <motion.div
                            className="hidden sm:block"
                            style={{width: lineMargin}}
                        ></motion.div>
                    </div>

                    <div className="flex flex-row w-screen absolute top-[108px] sm:top-[129px]">
                        <motion.div
                            className="hidden sm:block"
                            style={{width: lineMargin}}
                        ></motion.div>
                        <motion.div
                            className="w-full border-b-[2px] border-text"></motion.div>
                        <motion.div
                            className="hidden sm:block"
                            style={{width: lineMargin}}
                        ></motion.div>
                    </div>

                    <motion.div
                        style={{height: lineHeight}}
                        className="absolute left-[300px] top-[10px] sm:top-8 h-[99px] sm:h-[50px] mx-4 sm:mx-10 mr-20 border-r-[2px] border-text"></motion.div>
                    <motion.div
                        style={{opacity: scrollIndicatorOpacity}}
                        className="absolute scale-75 sm:scale-100 top-[25px] left-[210px] sm:top-[43px] sm:left-[360px]">
                        <ScrollIndicator/>
                    </motion.div>
                    <nav className="relative m-4 sm:m-10 w-fit text-xl font-raleway">
                        <div
                            className={`absolute square transition-all bg-text w-[21px] h-[21px] ${isMoving ? 'circle' : ''} ${!isClicked ? 'hidden' : ''}`}
                            style={{top: `${squarePosition}px`}}></div>
                        <ul>
                            <li>
                                <Link className={`w-fit nav-item ${isClicked ? 'opacity-0' : ''}`} to="/biographie"
                                      ref={bioButtonRef}
                                      onClick={() => {
                                          setIsClicked(true);
                                          setCurrentSubsection('Biographie');
                                          setSelectedLink('Biographie');
                                          setSquarePosition(2);
                                          setIsMoving(true);
                                          setTimeout(() => setIsMoving(false), 300);
                                      }}
                                      className={`group relative ${selectedLink === 'Biographie' ? 'selected' : ''}`}>
                                    {selectedLink !== 'Biographie' && <>Biographie <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className="opacity-0 -translate-x-4 group-hover:opacity-100 transition-all duration-200 ease-in-out transform group-hover:translate-x-1"/></>}
                                </Link>
                            </li>
                            <li>
                                <Link to="/projects" onClick={() => {
                                    setCurrentSubsection('Mes projets');
                                    setSelectedLink('Mes projets');
                                    setSquarePosition(32);
                                    setIsMoving(true);
                                    setIsClicked(true);
                                    setTimeout(() => setIsMoving(false), 300);
                                }}
                                      className={`group relative ${selectedLink === 'Mes projets' ? 'selected' : ''}`}>
                                    {selectedLink !== 'Mes projets' && <>Mes projets <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className="opacity-0 -translate-x-4 group-hover:opacity-100 transition-all duration-200 ease-in-out transform group-hover:translate-x-1"/></>}
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact" onClick={() => {
                                    setCurrentSubsection('Contact');
                                    setSelectedLink('Contact');
                                    setSquarePosition(62);
                                    setIsMoving(true);
                                    setIsClicked(true);
                                    setTimeout(() => setIsMoving(false), 300);
                                }}
                                      className={`group relative ${selectedLink === 'Contact' ? 'selected' : ''}`}>
                                    {selectedLink !== 'Contact' && <>Contact <FontAwesomeIcon icon={faArrowRight}
                                                                                              className="opacity-0 -translate-x-4 group-hover:opacity-100 transition-all duration-200 ease-in-out transform group-hover:translate-x-1"/></>}
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div
                        className={`big-heading flex items-center justify-center text:lg sm:text-4xl xl:text-6xl text-right text-text tracking-[0px] font-extrabold mr-4 sm:mx-10 mt-4 sm:mt-7 font-josefin whitespace-nowrap right-0 top-36 sm:top-48 ${animate ? 'animate' : ''}`}>
                        {currentSubsection}
                    </div>
                </motion.div>


                <TransitionGroup className="m-4 sm:m-10 font-raleway font-bold">
                    {/* eslint-disable-next-line no-restricted-globals */}
                    <CSSTransition key={location.key} classNames="slide" timeout={1000}>
                        {/* eslint-disable-next-line no-restricted-globals */}
                        <div className="relative sm:h-1/2 overflow-x-hidden sm:overflow-x-visible">
                            {/* eslint-disable-next-line no-restricted-globals */}
                            <Routes location={location}>
                                <Route path="/biographie" element={<Biographie/>}/>
                                <Route path="/projects" element={<Projects/>}/>
                                <Route path="/contact" element={<Contact/>}/>
                                <Route path="/project/:name" element={<ProjectDetails/>}/>
                                <Route path="/" element={<Projects/>}/>
                                <Route path="/portfolio" element={<Portfolio/>} />
                            </Routes>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>

        </Router>
    );
}

export default App;