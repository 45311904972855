import React from 'react';
import {useParams} from 'react-router-dom';
import projects from './projects.json';
import collaborators from './collaborators.json';
import ScrollIndicator from './scrollIndicator';
import {motion, useViewportScroll, useTransform} from 'framer-motion';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faReact, faJs, faHtml5, faCss3, faGitlab, faPhp, faJava} from '@fortawesome/free-brands-svg-icons';
import {faArrowRight, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import BackButton from "./BackButton";
import tailwindLogo from './img/logo/tailwind.png';
import javafxLogo from './img/logo/javafx.png';
import node from './img/logo/node.png';
import vercel from './img/logo/vercel.png';

function ProjectDetails() {
  const {name} = useParams();
  const project = projects.find(project => project.name === name);
  const {scrollYProgress} = useViewportScroll();
  const translateY = useTransform(scrollYProgress, [0, 1], ['0%', '10%']);

  const content = [];
  for (let i = 0; i < project.paragraphes.length; i++) {
    content.push(project.paragraphes[i]);
    if (project.illustrations && project.illustrations[i]) {
      content.push(project.illustrations[i]);
    }
  }

  const techToIcon = {
    'react': faReact,
    'javascript': faJs,
    'html': faHtml5,
    'css': faCss3,
    'gitlab': faGitlab,
    'php': faPhp,
    'mysql': 'faDatabase',
    'java': faJava,
    'twig': 'fas fa-leaf',
    'tailwind': tailwindLogo,
    'vercel': vercel,
    'node': node,
    'javafx': javafxLogo
  };

  const techToColor = {
    'react': '#61dafb',
    'javascript': '#f7df1e',
    'html': '#e34f26',
    'css': '#1572b6',
    'gitlab': '#fc6d26',
    'php': '#8892bf',
    'mysql': '#00758f',
    'java': '#b07219',
    'twig': '#8fbf3f',
    'tailwind': '#06b6d4'
  };

  const projectCollaborators = project.contributeurs
  ? collaborators.filter(collaborator => project.contributeurs.includes(collaborator.name))
  : [];

  return (
    <div className="subsection max-w-[1000px] mx-auto text-text">
      <div className="top-[134px] left-1/2 sm:top-[50px]">
        <BackButton/>
      </div>
      <div className="flex flex-col lg:flex-row justify-between border-b-2 border-text">
        <h2
          className="font-bold text-6xl sm:text-[9.5rem] pb-5 sm:pb-0 big-heading border-b-2 border-text sm:border-none">{project.name}</h2>
        <div className="flex flex-col justify-end gap-7 mb-10 mt-5 lg:mt-0 relative">
          <h3 className="text-4xl font-semibold title">{project.date}</h3>
          <div className="flex flex-row gap-5 justify-evenly">
            {project.technologies.map((technology, index) => {
              const icon = techToIcon[technology];
              return typeof icon === 'string' ? (
                <img
                  src={icon}
                  alt={technology}
                  key={index}
                  className="w-8 h-8 sm:!w-fit sm:!h-12 overflow-hidden object-cover"
                  title={technology}
                />
              ) : (
                <FontAwesomeIcon
                  icon={icon}
                  style={{ backgroundColor: technology === 'javascript' ? 'black' : 'transparent' }}
                  color={techToColor[technology]} key={index}
                  className="w-8 h-8 sm:w-12 sm:h-12 overflow-hidden"
                  title={technology}
                />
              );
            })}
          </div>
        </div>
      </div>
      {project.url &&
        <a href={project.url} target="_blank" rel="noreferrer"
           className="group text-2xl font-semibold flex flex-row items-center justify-between whitespace-nowrap mb-5 py-3 border-b-2 border-text">
          Visiter le site
          <div className="w-6 overflow-hidden">
            <FontAwesomeIcon
              icon={faArrowRight}
              className="mt-1 -translate-x-7 opacity-100 transition-all duration-200 ease-in-out transform group-hover:translate-x-0 whitespace-nowrap"
            />
            <FontAwesomeIcon
              icon={faArrowRight}
              className="ml-2 mt-1 -translate-x-7 opacity-100 transition-all duration-200 ease-in-out transform group-hover:translate-x-0 whitespace-nowrap"
            />
          </div>
        </a>
      }
      {content.map((item, index, array) => {
        if (item.includes('img/')) {
          return (
            <div id="projectDetailPargraphe" key={index}
                 className={`w-full flex-col sm:flex-col sm:flex ${index % 2 === 0 ? 'sm:flex-row-reverse' : ''}`}>
              <p>{array[index - 1]}</p>
              <div className="w-full overflow-hidden my-10 sm:mt-2 h-fit rounded-lg">
                <motion.img src={`${process.env.PUBLIC_URL}/${item}`}
                            alt="illustration"
                            className="rounded-xl w-full"/>
              </div>
            </div>
          );
        } else if (!array[index + 1] || !array[index + 1].includes('img/')) {
          return <p key={index}>{item}</p>;
        }
        return null;
      })}
      {projectCollaborators.length > 0 &&
        <div className="my-20">
          <h3 className="text-4xl mb-3 font-semibold title">
            {`Collaborateur${projectCollaborators.length > 1 ? 's' : ''} du projet`}
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
            {projectCollaborators.map((contributor, index) => (
              <a key={index}
                 href={contributor.linkedin} target="_blank" rel="noopener noreferrer"
                 className="border border-text rounded-md shadow p-4 w-full h-auto flex justify-between items-center bg-white">
                <div className="flex flex-row justify-start items-center">
                  <img src={contributor.pp} alt={contributor.name} className="w-12 h-12 rounded-full mr-4"/>
                  <h4 className="font-bold">
                    {contributor.name}
                  </h4>
                </div>
                <button className="border border-[#0077B5] text-[#0077B5] font-bold py-1 px-2 rounded-full">
                  Contacter
                </button>
              </a>
            ))}
          </div>
        </div>
      }
      {project.competences && project.competences.length > 0 &&
        <div>
          <h3 className="text-4xl mb-3 font-semibold title">Compétences</h3>
          <p className="mb-5">Cette section est en accord avec le référentiel de compétences du BUT
            Informatique de l'IUT : <a
              href={process.env.PUBLIC_URL + '/PN-BUT-Info_2023.pdf'} target="_blank"
              rel="noopener noreferrer">
              Référentiel BUT Informatique
            </a></p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
            {project.competences.map((competence, index) => (
              <div key={index} className="border border-text rounded-md shadow p-4 w-full h-auto">
                <div className="flex flex-row justify-start">
                  <p className="whitespace-nowrap">{competence.number} : </p>
                  <h3 className="font-bold ml-2">{competence.title}</h3>
                </div>
                <p className="my-4">Niveau estimé : {competence.niveauEstime}</p>
                <p>Exemple: {competence.exemple}</p>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  );
}

export default ProjectDetails;