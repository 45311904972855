import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGithub, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {faFilePdf, faPhone, faEnvelope, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';

function Contact() {
  return (
    <div className="subsection m-auto h-fit w-fit mt-52">
      <div className="hidden">
        <Link to="/portfolio" className="w-full text-4xl underline">Lien Vers Mon Portfolio d'apprentissage
          <i className="ml-2"><FontAwesomeIcon icon={faArrowRight}/></i>
        </Link>
      </div>
      <h1 className="text-4xl font-bold my-16 w-fit mx-auto">Prenons Contact !</h1>
      <div
        className="contact flex flex-col sm:flex-row justify-center align-middle space-y-5 sm:space-y-0 sm:space-x-12">
        <div className="cursor-pointer">
          <a href="https://github.com/Soren-Starck" target="_blank" rel="noopener noreferrer"
             title="Mon GitHub"
             className="transition-colors duration-300">
            <FontAwesomeIcon icon={faGithub} size="5x" style={{color: '#181717'}}/>
          </a>
          <p>GitHub</p>
        </div>
        <div className="cursor-pointer">
          <a href="https://www.linkedin.com/in/soren-starck/" target="_blank" rel="noopener noreferrer"
             title="Mon LinkedIn" className="transition-colors duration-300">
            <FontAwesomeIcon icon={faLinkedin} size="5x" style={{color: '#0A66C2'}}/>
          </a>
          <p>LinkedIn</p>
        </div>
        <div className="cursor-pointer">
          <a href={process.env.PUBLIC_URL + '/CV_Soren_Starck.pdf'} target="_blank" rel="noopener noreferrer"
             title="Mon CV" className="transition-colors duration-300">
            <FontAwesomeIcon icon={faFilePdf} size="5x" style={{color: '#ED1C24'}}/>
          </a>
          <p>CV</p>
        </div>
        <div className="cursor-pointer">
          <a href="tel:+33767663511" title="Mon Téléphone" className="transition-colors duration-300">
            <FontAwesomeIcon icon={faPhone} size="5x" style={{color: '#007BFF'}}/>
          </a>
          <p>Téléphone</p>
        </div>
        <div className="cursor-pointer">
          <a href="mailto:soren.starck@free.fr" title="Mon Email" className="transition-colors duration-300">
            <FontAwesomeIcon icon={faEnvelope} size="5x" style={{color: '#DC3545'}}/>
          </a>
          <p>Email</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;