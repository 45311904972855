import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import projects from './projects.json';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';

function Projects() {
    const [hoveredProject, setHoveredProject] = useState(null);

    return (
        <div
            className="subsection flex flex-col lg:flex-row w-full lg:mx-10 lg:mb-5 h-full sm:h-[calc(100vh-370px)] lg:fixed bottom-0 left-0">
            <div id="imgList"
                 className="invisible sm:visible relative w-1/2 h-0 lg:h-full overflow-hidden rounded-lg mr-10 mb-10 flex items-center justify-center">
                <p className="animate-pulse text-gray-500 opacity-80">Survolez les projets à droite</p>
                {projects.map((project, index) => (
                    <img className="absolute object-cover h-full sm:w-full rounded-lg" style={{
                        opacity: hoveredProject === index ? 1 : 0,
                        transform: hoveredProject === index ? 'scale(1.1)' : 'scale(1.0)',
                        transition: 'opacity .5s, transform .5s',
                    }}
                         src={`${process.env.PUBLIC_URL}/${project.picture}`} alt={project.name}
                         key={index}/>
                ))}
            </div>
            <div
                className="text-2xl my-2 flex flex-col lg:w-1/2 w-full h-auto lg:h-full mb-20 lg:mr-20 overflow-y-visible lg:overflow-y-scroll">
                {projects.map((project, index) => (
                    <Link to={`/project/${project.name}`} key={index}>
                        <div
                            className={`project-card w-full h-[100px] relative flex flex-row items-center border-t-2 ${index === projects.length - 1 ? 'border-b-2' : ''} border-black py-2`}
                            onMouseEnter={() => setHoveredProject(index)}
                            onMouseLeave={() => setHoveredProject(null)}>
                            <div className="flex flex-row items-center justify-center w-1/2">
                                <h2 className="text-xl xl:text-2xl absolute left-0 whitespace-nowrap transition group-hover:drop-shadow-lg">{project.name}<FontAwesomeIcon
                                    icon={faArrowRight}
                                    className={`ml-[10%] opacity-0 -translate-x-4 group-hover:opacity-100 transition-all duration-200 ease-in-out transform group-hover:translate-x-4 whitespace-nowrap ${hoveredProject === index ? 'opacity-100 translate-x-1' : 'opacity-0 -translate-x-4'}`}/>
                                </h2>
                                <h2 className="invisible 2xl:visible text-sm xl:text-base absolute left-1/4 lg:left-[38%] whitespace-nowrap">{project.date}</h2>
                            </div>
                            <h3 className="absolute left-1/4 sm:left-1/4 text-sm text-gray-700 text-right sm:text-left xl:text-base ml-[20%] lg:ml-[40%]">{project.resume}</h3>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default Projects;