import React from 'react';

function Biographie() {
    return (
        <div className="subsection relative max-w-[1000px] mx-auto">
            <h2 className="text-6xl sm:text-8xl my-5 whitespace-nowrap big-heading">À propos</h2>
            <div
                id="biographie"
                className="mt-[60px] font-normal flex flex-col gap-14 xl:gap-[80px] text-justify max-md:text-[22px] max-md:leading-[30px] md:leading-[35px] md:text-[30px] xl:text-[40px] md:tracking-[-1.2px] xl:leading-[50px]">
                <p>Je suis actuellement en troisième année d'<span>école d'ingénieur</span> à <a href="https://polytech.univ-lyon1.fr/formation/alternance">Polytech Lyon</a> en Informatique. Je suis également en <span>alternance chez <a href="https://getyooz.com">YOOZ</a></span> en tant qu'ingénieur de recherche dans le domaine de l'<span>intelligence artificielle</span><br></br>En
                    2022
                    j'ai
                    obtenu mon Baccalauréat Général avec les spécialités <span>Mathématiques et Physique-Chimie</span>,
                    les options
                    Mathématiques Expertes et Discipline Non Linguistique Physique-Chimie en Anglais au Lycée Vincent
                    d’Indy à Privas.<br></br> En 2021 j'ai suivi un MOOC en <span>Sécurité Informatique</span> proposé
                    par la
                    SecNumacadémie de l'ANSSI en 2021.</p>
                <p>Durant mes deux années à l'<span>iut de Montpellier en informatique</span>, j'ai acquis une solide base en développement orienté objet
                    avec Java et développement bas niveau avec le
                    C. J'ai pu apprendre de manière très poussée le Framework <span>Angular</span> lors de mon stage
                    chez <span>YOOZ</span> en 2024. Je me
                    suis également formé en autodidacte au Framework <span>React</span> en
                    parrallèle avec l'apprentissage de <span>TailwindCSS</span> qui me permet de développer des sites
                    webs attractifs et dynamiques.
                    Je maîtrise également d'autres langages et framework, notamment <span>Symphony, JavaScript, SQL, PHP, HTML,
                    CSS,
                    JavaFX, et Python</span>.<br></br>J'ai également de l'expérience dans le développement d'extensions
                    pour
                    l'environnement de bureau Linux GNOME et la maîtrise de <span>GIT</span>.<br></br>Ces projets m'ont
                    appris à
                    créer,
                    déployer, gérer et interagir avec une base de données avec <span>MySQL</span>.<br></br>J'ai
                    développé des
                    compétences
                    en communication interpersonnelle et en coordination de <span>projets de groupe</span>.<br></br>En
                    tant que
                    co-administrateur du serveur de communication de mon IUT, je gère une communauté en ligne de 500
                    personnes, favorisant la <span>communication</span> et
                    la <span>collaboration interdisciplinaires</span>. Cette expérience a
                    renforcé mes compétences de gestion de communauté et de résolution de problèmes techniques.<br></br>Par
                    ailleurs, j'ai mobilisé mes capacités créatives et artistiques en réalisant les logos de la
                    promotion
                    IUT informatique pour les années 2022-2023 et 2023-2024.</p>
                <p>
                    Mes centres d'intérêt sont variés.<br></br>Je fais également du <span>cyclisme sur route</span> et
                    du <span>VTT</span> chaque
                    semaine, ce qui renforce mon endurance et mon goût de l'effort. J'ai pratiqué l'escalade en
                    compétition pendant 10 ans, ce qui a développé ma rigueur, ma persévérance et mon humilité.<br></br>J'ai
                    été membre de troupes de <span>théâtre classique et d'improvisation</span>, ce qui a renforcé ma
                    créativité et
                    mes compétences communicationnelles.<br></br>Je suis curieux et j'ai plaisir à voyager pour
                    notamment découvrir de nouveaux massifs montagneux. J'aime lire des romans d'anticipation et des
                    bandes dessinés.
                </p>
            </div>
        </div>
    );
};

export default Biographie;