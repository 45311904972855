import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function BackButton() {
    const navigate = useNavigate();

    return (
        <button onClick={() => navigate(-1)}
                className="group text-2xl font-semibold flex flex-row items-center justify-between whitespace-nowrap mb-5 py-3">
            <FontAwesomeIcon
                icon={faArrowLeft}
                className="mr-2 translate-x-0 opacity-100 transition-all duration-200 ease-in-out transform group-hover:-translate-x-4 whitespace-nowrap"
            />
            Retour
        </button>
    );
}

export default BackButton;